import { Axios } from '@/network/axios';

export default class Collection {
  getAccidentTypes = () => {
    return Axios.get('accident/types');
  };

  uploadClaims = (fileData: any, config = {}) => {
    return Axios.post('upload/claims', fileData, config);
  };

  // NB: this endpoint is also referenced in a service worker
  // before u rename it, please do search and replace
  uploadVideo = (fileData: any, config = {}) => {
    return Axios.post('vapor/claims/upload', fileData, config);
  };

  submitClaims = (claimsData: any) => {
    try {
      return Axios.post('submit/claims', claimsData);
    } catch (e) {
      console.error(e);
    }
  };

  addQuotes = (quotes: any, session_id: null | string = null) => {
    const quoteData = {
      id: localStorage.id,
      quotes,
      session_id,
    };
    return Axios.post('add/quotes', quoteData);
  };

  mlValidateSingleUpload = (data: object) => {
    return Axios.post('detect/single', data);
  };

  static getClaims = (qeury = {}) => {
    return Axios.get('/collections', { params: qeury });
  };

  static getClaim = (id: string | number, qeury = {}) => {
    return Axios.get(`/collections/${id}`, { params: qeury });
  };

  static acceptClaim = (id: string | number) => {
    return Axios.post(`/collections/${id}/accept`);
  };

  static disputeClaim = (id: string | number, data: any) => {
    return Axios.post(`/collections/${id}/dispute`, data);
  };

  static verifyTransaction = (id: string, query = {}) => {
    return Axios.get('/collections/verify-transaction-id', {
      params: { ...query, transaction_id: id },
    });
  };
}
